.page {
  padding-bottom: $lg3;
  z-index: 2;
  position: relative;

  // .white__tabs {
  //   color: white;
  //   .ant-tabs-tab {
  //     color: $color-primary;
  //   }
  //   .ant-tabs-tab-btn {
  //     color: white;

  //     &:hover {
  //       color: $color-primary;
  //     }
  //   }
  //   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //     color: $color-primary;
  //   }
  // }
}

.header__image {
  width: 100vw;
  height: 12.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 4.5rem;
  background-image: url('https://images.unsplash.com/photo-1459278558918-f94278c0f022?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1052&q=80');
  background-position: top;
  background-size: cover;
}

.employee__card {
  border-radius: $sm2;
  background-color: white;
  box-shadow: $shadow;

  padding: $md2;
  height: fit-content;

  &--clear {
    box-shadow: none;
  }
  
  &--col {
    display: flex;
    flex-direction: column;
    align-items: center;

    .extras {
      width: calc(100% + 2rem);;
    }
  }
  
  .card__image {
    border-radius: 50%;
    object-fit: cover;
  }

  .name {
    margin-top: $md1;
  }

  .extras {
    padding: $sm3;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fdba334a;
    margin: $md1 0;

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
      }

      .caption {
        font-size: $sm3;
        margin-bottom: -0.5rem;
      }

      .amount {
        font-size: $md3;
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .employee__details {
    list-style: none;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: $sm3;
        margin-bottom: $sm2;

        &.noMargin {
          margin: 0;
        }

        &:first-child {
          color: $text-label;
        }
      }
    }
  }
}

.operational__card {
  background-color: white;
  padding: $md3;
  border-radius: $sm2;
  box-shadow: $shadow;

  .tags {
    width: 9rem;
    display: flex;
    align-items: center;
  }
}
.card__item {
  flex: 1 auto;

  &--spaceBottom {
    margin-bottom: $md1;
  }

  p.caption {
    font-size: $sm2;
    text-transform: uppercase;
    color: $text-label;
    margin: 0;
  }
}
p.caption {
  font-size: $sm2;
  text-transform: uppercase;
  color: $text-label;

  &.lower {
    text-transform: inherit;
  }
}

.testPanel {
  .ant-collapse-header {
    font-size: $md1;
    font-weight: bold;
  }
}

@import '~antd/dist/antd.css';

// GLOBAL
@import './global';

// PAGES
@import './pages/employee';

// LAYOUTS


// @for $i from 1 through $repeat {
//   .suc-card .ant-card-head:nth-child(#{length($colors)}n+#{$i}) {
//       background: lighten(nth($colors, random(length($colors))), 20%);
//   }
// }
// BLOCKS
.blur-z {
  z-index: 49;
}

.modal {
  &--noPadding {
    .ant-modal-body {
      padding: 0;
    }
  }
}

.snap-obj-card {
  .ant-card-head-title {
    white-space: pre-wrap;
  }
}

.suc-card {

  &--0, &--green {
    .ant-card-head {
      background-color: rgb(26, 92, 67);
            color: white;
    }
  }
  &--1, &--yellow {
    .ant-card-head {
      background-color: rgb(253, 185, 50);
            color: white;
    }
  }
  &--2, &--blue {
    .ant-card-head {
      background-color: rgb(86, 145, 198);
      color: white;
    }
  }
  &--3, &--black {
    .ant-card-head {
      background-color: rgb(0, 0, 0);
            color: white;
    }
  }
  &--lightYellow {
    .ant-card-head {
      background-color: rgba(209, 209, 209, 0.269);
    }
  }
}

.brown-row {
  background-color: #28211F !important;
  color: white !important;
}
.yellow-row {
  background-color: #FDE2AB !important;

}
.gray-row, .grey-row {
  background-color: #D8D8D8 !important;

}
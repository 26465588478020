* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

body {
  background: #e8e8e82b;
}

// COLORS
$color-primary: #fdb933;
$color-secondary: #fdb933;
$color-tertiary: #2746b0;
$color-success: #7fba7a;
$color-warning: #fec22d;
$color-danger: #ff0000;

// TEXT
$text-default: #242425;
$text-secondary: #fff;
$text-label: #808291;
$text-disabled: #e1dfe9;

// SIZE
$sm1: 0.375rem;
$sm2: 0.625rem;
$sm3: 0.875rem;

/* MEDIUM */
$md1: 1rem;
$md2: 1.375rem;
$md3: 1.75rem;

/* LARGE */
$lg1: 2.5rem;
$lg2: 3.5rem;
$lg3: 4.5rem;

$shadow: 3px -3px 20px -2px $text-disabled;

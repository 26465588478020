@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .statusTodo {
    @apply text-red-500
  }
  .statusInProgress {
    @apply text-yellow-500
  }
  .statusDone {
    @apply text-green-500
  }
}

@media screen and (max-width: 1024px) {
  .employees__page, 
  .worklist__page,
  .reporting__page,
  .succession__page {
    width: 96vw;
    margin: auto;
  }
  .employees__page .hasImage .ant-image {
    width: 4rem;
  }
  .employees__page .hasImage .hasLongContent {
    width: calc(100% - 5rem);
  }
}

.employees__page .email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

